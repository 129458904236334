@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  overflow: auto;

}

body {
  position: relative;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: #F5F5F5;
  font-family: "Pretendard";
  letter-spacing: -0.035em;
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('../public/fonts/Pretendard-Bold.woff2') format('woff2')
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('../public/fonts/Pretendard-SemiBold.woff2') format('woff2')
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('../public/fonts/Pretendard-Medium.woff2') format('woff2')
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('../public/fonts/Pretendard-Regular.woff2') format('woff2')
}


.App {
  background-color: red;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile {
  height: 30vh;
  pointer-events: none;
}


.file--image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.stylish-button {
  border: none;
  outline: none;
  background-color: #007BFF;
  color: white;
  padding: 20px 40px; /* Increased padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px; /* Increased font size */
  border-radius: 10px; /* Slightly larger border-radius */
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;

  margin-bottom: 48px;
}

.stylish-button:hover {
  background-color: #0056b3;
  transform: scale(1.1); /* Slightly larger scale on hover */
}

.stylish-button:active {
  background-color: #004085;
  transform: scale(0.9);
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fadeOut;
  animation-delay: 2s;
  animation-duration: .3s; /* Adjust the duration as needed */
  animation-fill-mode: forwards; /* Keeps the element in the final state when the animation completes */
}

.no-default-select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png') no-repeat right center; /* Replace with your custom arrow image */
}

html::-webkit-scrollbar {
  height: 0px;
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.invisible-scrollbar::-webkit-scrollbar {
  height: 0px;
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Hide scrollbar for IE, Edge and Firefox */
.invisible-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overscroll-behavior: none;
}


.iframe-glow {
  margin: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #e0e0e0, #f8f8f8);
  background-size: 200% 200%;
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.child-hover {
  visibility: hidden;
}
.parent-hover:hover .child-hover {
  visibility: visible;
}

.bulletoutside .swiper-pagination {
  top: 100%!important
}

.max-w-screen-xs {
  max-width: 480px!important;
}